<template>
  <LiefengModal
      title="标签分类管理"
      :fullscreen="fullscreen"
      :value="value"
      ref="modelChildren"
  >
    <template v-slot:contentarea>
      <div class="container">
        <div class="header">
          <Button type="primary" @click="labelAddFn">批量新增标签</Button>
        </div>
        <LiefengTableNew
            ref="table"
            :talbeColumns="tableColumns"
            :curPage="page"
            :total="total"
            :tableData="tableData"
            :height="tableHeight"
            :loading="loading"
            :pagesizeOpts="[50,200,500,1000]"
            :pageSize="pageSize"
            @hadlePageSize="hadlePageSize"
            @tableSelect="tableSelect"
        ></LiefengTableNew>
        <!--        新增-->
        <labeladd
            :fullscreen="false"
            :toggle-fn="labelAddFn"
            :toggle-down="downFn"
            :value2="value2"
            @toggle="toggle"
            :page-fn="hadlePageSize"
            :page="page"
            :pageSize="pageSize"
        >
        </labeladd>

        <!-- 修改 -->
        <LiefengModal
            :title="addChangeTitle"
            :value="addChangeStatus"
            width="450px"
            height="120px"
            @input="addChangeStatusFn"
        >
          <template #contentarea>
            <Form
                :rules="addChangeFormValidate"
                :model="addChangeForm"
                :label-width="80"
                ref="addChangeForm"
            >
              <FormItem label="标签名称" prop="labelName">
                <Input
                    :maxlength="20"
                    v-model.trim="addChangeForm.labelName"
                ></Input>
              </FormItem>
            </Form>
          </template>
          <template #toolsbar>
            <Button
                type="info"
                style="margin-right: 10px"
                @click="addChangeStatus = false"
            >取消
            </Button
            >
            <Button type="primary" @click="saveAddChange">保存</Button>
          </template>
        </LiefengModal>
      </div>
    </template>
    <template v-slot:toolsbar>
      <Button type="primary" @click="closeFn1">关闭</Button>
    </template>
  </LiefengModal>
</template>

<script>
//@route('/newlabel')
import LiefengModal from "@/components/LiefengModal";
import LiefengTableNew from "@/views/column/children/LiefengTableNew";
import labeladd from "@/views/column/children/children1/labeladd";

export default {
  props: {
    value: Boolean,
    fullscreen: {
      default: Boolean
    },
    toggleFlag: Function,
    closeFn1: Function
  },
  data() {
    return {
      //   修改
      infoId: "",
      addChangeTitle: "",
      addChangeStatus: false,
      addChangeForm: {labelName: ""},
      addChangeFormValidate: {
        labelName: [
          {required: true, message: "请输入标签名称", trigger: "blur"},
        ],
      },


      value2: false, //批量添加
      removeTableSelectArr: [], // need delete
      tableHeight: '',
      orgList: [],
      orgCode: "",
      orgCodeName: '',
      tableColumns: [
        {
          title: "标签名称",
          key: "labelName",
          minWidth: 240,
          align: "center",
        },
        {
          title: "标签同步数据量",
          key: "columnNum",
          minWidth: 140,
          align: "center",
        },

        {
          title: "操作",
          align: "center",
          width: 200,
          fixed: "right",
          render: (h, params) => {
            return h("div", [
              h(
                  "Button",
                  {
                    props: {
                      type: "primary",
                      size: "small",
                    },
                    style: {
                      marginRight: "10px",
                    },
                    on: {
                      click: () => {
                        this.$Message.loading({
                          content: "正在获取数据，请稍等...",
                          duration: 0
                        })
                        this.$get(
                            "/datamsg/api/pc/platform/label/selectLabelById",
                            {
                              labelId: params.row.id,
                              operatorCustGlobalId:
                              parent.vue.loginInfo.userinfo.custGlobalId,
                              orgCode: parent.vue.loginInfo.userinfo.orgCode,
                              oemCode: parent.vue.oemInfo.oemCode,
                              terminal: "2",
                            }
                        )
                            .then((res) => {
                              if (res.code == 200 && res.data) {
                                this.infoId = params.row.id;
                                this.$refs.addChangeForm.resetFields();
                                this.addChangeForm.labelName = res.data.labelName;
                                this.addChangeTitle = "修改标签";
                                this.$Message.destroy();
                                this.addChangeStatus = true;
                              } else {
                                this.$Message.destroy();
                                this.$Message.error({
                                  background: true,
                                  content: res.desc,
                                });
                              }
                            })
                            .catch((err) => {
                              console.log(err);
                              this.$Message.destroy();
                              this.$Message.error({
                                background: true,
                                content: "获取数据失败，请联系管理员处理",
                              });
                            });
                      },
                    },
                  },
                  "修改"
              ),
              h(
                  "Button",
                  {
                    props: {
                      type: "error",
                      size: "small",
                    },
                    on: {
                      click: () => {
                        this.$Modal.confirm({
                          title: "温馨提示",
                          content: `您正在删除<span style="color: red"> ${params.row.labelName} </span>标签，此操作不可逆，是否继续?`,
                          onOk: () => {
                            this.$post(
                                "/datamsg/api/pc/platform/label/deletePlatformLabel",
                                {
                                  labelId: params.row.id,
                                  operatorCustGlobalId:
                                  parent.vue.loginInfo.userinfo.custGlobalId,
                                  orgCode: parent.vue.loginInfo.userinfo.orgCode,
                                  oemCode: parent.vue.oemInfo.oemCode,
                                  terminal: "2",
                                }
                            )
                                .then((res) => {
                                  if (res.code == 200) {
                                    this.$Message.success({
                                      background: true,
                                      content: "删除成功",
                                    });
                                    this.hadlePageSize({
                                      page: this.page,
                                      pageSize: this.pageSize,
                                    });
                                  } else {
                                    this.$Message.error({
                                      background: true,
                                      content: res.desc,
                                    });
                                  }
                                })
                                .catch((err) => {
                                  console.log(err);
                                  this.$Message.error({
                                    background: true,
                                    content: "删除失败，请联系管理员处理",
                                  });
                                });
                          },
                        });
                      },
                    },
                  },
                  "删除"
              ),
            ])
          },
        },
      ],
      //表格1列表获取参数
      postData: {},
      inputVal: '',
      tableData: [],
      loading: false,
      pageSize: 20,
      total: 0,
      page: 1,
      tableSelectArr: [],
      rightList: [],
      removeRightList: [],
    };
  },
  methods: {
    toggle(val) {
      this.value2 = val
    },
    addChangeStatusFn(status) {
      this.addChangeStatus = status;
    },
    //保存修改
    saveAddChange() {
      this.$refs.addChangeForm.validate((status) => {
        if (status) {
          let params = {
            labelName: this.addChangeForm.labelName,
            oemCode: parent.vue.oemInfo.oemCode,
            labelType: "1",
          };
          if (this.addChangeTitle == "修改标签") {
            params.id = this.infoId;
          }
          this.$post("/datamsg/api/pc/platform/label/savePlatformLabel", params)
              .then((res) => {
                if (res.code == 200) {
                  this.$Message.success({
                    background: true,
                    content: "保存成功",
                  });
                  this.hadlePageSize({
                    page: this.page,
                    pageSize: this.pageSize,
                  });
                  this.addChangeStatus = false;
                } else {
                  this.$Message.error({
                    background: true,
                    content: res.desc,
                  });
                }
              })
              .catch((err) => {
                this.$Message.error({
                  background: true,
                  content: "保存失败，请联系管理员处理",
                });
              });
        }
      });
    },
    //新增打开按钮
    labelAddFn() {
      this.value2 = true
    },
    //新增关闭
    downFn() {
      this.value2 = false
    },
    // 表格选中
    tableSelect(data) {
      this.tableSelectArr = JSON.parse(JSON.stringify(data));
    },
    tableSelect1(data) {
      this.removeTableSelectArr = JSON.parse(JSON.stringify(data));
    },
    // 获取表格数据
    hadlePageSize(obj) {
      this.loading = true;
      this.$get("/datamsg/api/pc/platform/label/selectPlatformLabelPage", {
        oemCode: parent.vue.oemInfo.oemCode,
        labelType: this.$route.query.labelType || "0", //标签类型
        page: obj.page,
        pageSize: obj.pageSize,
      })
          .then((res) => {
            this.$Message.destroy()
            this.loading = false;
            if (res.code == 200 && res.dataList) {
              this.tableData = res.dataList;
              this.page =res.currentPage;
              this.pageSize = res.pageSize;
              this.total = res.maxCount;
            } else {
              this.$Message.destroy()
              this.$Message.error({
                background: true,
                content: res.desc,
              });
            }
          })
          .catch((err) => {
            this.$Message.destroy()
            console.log(err);
            this.$Message.error({
              background: true,
              content: "获取数据失败。请重试",
            });
          });

    },
  },
  created() {

  },
  beforeMount() {
    this.tableHeight = window.innerHeight - 200 + '';
  },

  components: {
    LiefengModal,
    LiefengTableNew,
    labeladd,
  },
};
</script>

<style scoped lang='less'>
.container {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 123px);

  > .header {
    margin: 0px 20px 20px 20px;

    > BUtton {
      padding: 5px;
    }
  }
}

/deep/ .ivu-modal-close {
  display: none;
}
</style>
