<template>
  <LiefengModal
      title="新增标签"
      :fullscreen="fullscreen"
      :value="value2"
      @click="toggleFn"
  >
    <template v-slot:contentarea>
      <div class="container">
        <template>
          <Form :model="formLeft" id="formDow" label-position="left" :label-width="80">
            <FormItem label="标签1">
              <Input v-model.trim="formLeft.input1" placeholder="限10个字" maxlength="10"></Input>
            </FormItem>
            <FormItem label="标签2">
              <Input v-model.trim="formLeft.input2" placeholder="限10个字" maxlength="10"></Input>
            </FormItem>
            <FormItem label="标签3">
              <Input v-model.trim="formLeft.input3" placeholder="限10个字" maxlength="10"></Input>
            </FormItem>
            <FormItem label="标签4">
              <Input v-model.trim="formLeft.input4" placeholder="限10个字" maxlength="10"></Input>
            </FormItem>
            <FormItem label="标签5">
              <Input v-model.trim="formLeft.input5" placeholder="限10个字" maxlength="10"></Input>
            </FormItem>
            <FormItem label="标签6">
              <Input v-model.trim="formLeft.input6" placeholder="限10个字" maxlength="10"></Input>
            </FormItem>
            <FormItem label="标签7">
              <Input v-model.trim="formLeft.input7" placeholder="限10个字" maxlength="10"></Input>
            </FormItem>
            <FormItem label="标签8">
              <Input v-model.trim="formLeft.input8" placeholder="限10个字" maxlength="10"></Input>
            </FormItem>
            <FormItem label="标签9">
              <Input v-model.trim="formLeft.input9" placeholder="限10个字" maxlength="10"></Input>
            </FormItem>
            <FormItem label="标签10">
              <Input v-model.trim="formLeft.input10" placeholder="限10个字" maxlength="10"></Input>
            </FormItem>
          </Form>

        </template>

      </div>
    </template>
    <template v-slot:toolsbar>
      <div class="but">
        <Button @click="submitFn">保存</Button>
      </div>
      <Button type="primary" @click="toggleDown">关闭</Button>
    </template>
  </LiefengModal>
</template>

<script lang="js">
import LiefengModal from "@/components/LiefengModal";

export default {
  name: "labeladd",
  props: {
    pageFn: {
      type: Function,
      default: null
    },
    value2: Boolean,
    fullscreen: Boolean,
    toggleFn: Function,
    toggleDown: Function,
    page: Number,
    pageSize: Number
  },
  data() {
    return {
      //input数
      formLeft: {},
    }
  },
  methods: {
    //新增保存
    submitFn() {
      console.log(this.formLeft, 'yy formList')
      let values = Object.values(this.formLeft)
      values = values.filter(item => item !== '')
      console.log(values.toString(), 'yy string')
      console.log(typeof values.toString(), 'yy string')
      if (values.toString() === '') {
        this.$Message.error({
          background: true,
          content: "保存失败，至少新增一个标签不能为空!",
        });
        return;
      } else {
        let params = {
          labelName: values.toString(),
          labelType: "0",
          oemCode: parent.vue.oemInfo.oemCode,
        };
        this.$post("/datamsg/api/pc/platform/label/savePlatformLabelList", params)
            .then((res) => {
              console.log(res, 'f')
              if (res.code >= 200 && res.code <= 300) {
                this.$Message.success({
                  background: true,
                  content: "保存成功",
                });
                this.$emit('toggle', false)

                if (this.pageFn) {
                  this.pageFn(
                      {
                        page: this.page,
                        pageSize: this.pageSize,
                      }
                  )
                }

                console.log(typeof this.page, 'page')
                console.log(this.page, 'page')
                this.formLeft = {}
              } else {
                this.$Message.error({
                  background: true,
                  content: res.desc,
                });
              }
            }).catch((err) => {
          console.log(err, '十')
          this.$Message.error({
            background: true,
            content: "保存失败，请联系管理员处理",
          });
        });
      }

    },
  },
  computed: {},
  components: {
    LiefengModal
  }
}
</script>

<style scoped lang="less">
#formDow {
  height: 400px;
  overflow: auto;
}

.but {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 20px;

  > Button {
    color: #10BC92
  }
}
</style>
