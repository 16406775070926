<template>
  <div>
    <announce ref="modelValue" :value-type="showMe" :fullscreen="true" :toggle-fn="toggle" :close-fn="close"></announce>
    <labelSettings ref="modelValue1" :value="showMe1" :fullscreen="true" :toggle-flag="toggle1"
                   :close-fn1="down"></labelSettings>
    <labelToggle ref="toggleLabel" @upData="size1" :toggleType1="toggleType1" :value="showMe2" :fullscreen="false"
                 :flag-type="flagType"
                 @click="flagType1"></labelToggle>
    <LiefengMain>
      <template v-slot:title>
        公众号专栏
        <div class="info-wrapper">
          <div>
            <Form :model="searchForm" :inline="true" :label-width="80">
              <FormItem label="当前状态">
                <Select v-model="searchForm.status" style="width: 160px">
                  <Option value="0">全部</Option>
                  <Option value="4">公布中</Option>
                  <Option value="-1">已下架</Option>
                </Select>
              </FormItem>
              <Button type="success" icon="ios-search" @click="search" style="margin-right: 10px">查询</Button>
              <Button @click="value3 = true" type="primary">详细查询</Button>
            </Form>
          </div>
        </div>
        <div class="left">
          <Button @click="toggle">添加公布内容</Button>
          <Button @click="toggle1">标签分类设置</Button>
        </div>
        <div class="right">
          <Button type="primary" @click="offShelf">批量下架</Button>
          <Button type="primary" @click="offAnnounce">批量公布</Button>
        </div>
      </template>
      <template v-slot:announce>
        <Drawer title="详细查询" v-model="value3" width="720" :mask-closable="false" :styles="styles" @input="cancel">
          <Form :model="searchForm" label-position="right" :label-width="100">
            <FormItem label="标题">
              <Input v-model.trim="searchForm.title"></Input>
            </FormItem>
            <FormItem label="编码">
              <Input v-model.trim="searchForm.code"></Input>
            </FormItem>
            <FormItem label="所属机构">
              <Input v-model.trim="searchForm.orgName"></Input>
            </FormItem>
            <FormItem label="初始公布方式">
              <Input v-model.trim="searchForm.source"></Input>
            </FormItem>
          </Form>
          <div class="demo-drawer-footer">
            <Button style="margin-right: 8px" @click="cancel">取消</Button>
            <Button style="margin-right: 8px" type="info" @click="reset">重置</Button>
            <Button type="success" @click="search">查询</Button>
          </div>
        </Drawer>
      </template>
      <template v-slot:contentArea>
        <LiefengTable
          :talbeColumns="talbeColumns"
          :tableData="tableData"
          :total="total"
          :curPage="page"
          @hadlePageSize="hadlePageSize"
          :loading="loading"
          :fixTable="true"
          :pagesizeOpts="[20,30, 50, 100]"
          :pageSize="pageSize"
          @tableSelect="tableSelect"
        ></LiefengTable>
      </template>
    </LiefengMain>
  </div>
</template>

<script>
//@route('/officialcolumn')
import LiefengMain from "@/views/column/LiefengMain";
import LiefengTable from "@/components/LiefengTable";
import announce from "@/views/column/children/announce"; //添加公布内容
import labelSettings from "@/views/column/children/labelSettings"; //标签分类管理
import labelToggle from "@/views/column/children/children1/labelToggle"; //管理标签

export default {
  data() {
    return {
      toggleType1: true,
      //end
      arrIdType: [],
      arrStatusType: [],
      paramsList: {},
      indexType: [],
      tableSelectArr: [],
      value3: false,
      styles: {
        height: "calc(100% - 55px)",
        overflow: "auto",
        paddingBottom: "53px",
        position: "static"
      },
      formData: {
        name: "",
        url: "",
        owner: "",
        type: "",
        approver: "",
        date: "",
        desc: ""
      },
      showMe: false,
      showMe1: false,
      showMe2: false,
      searchForm: {
        status: '4'
      },
      pageSize: 20,
      page: 1,
      total: 0,
      loading: false,
      tableData: [],
      cancelModalTip: true,
      talbeColumns: [
        {
          type: "selection",
          width: 60,
          align: "center"
        },
        {
          title: "标题",
          align: "center",
          minWidth: 120,
          key: "title"
        },
        {
          title: "编码",
          align: "center",
          width: 100,
          key: "code"
        },
        {
          title: "所属机构",
          align: "center",
          width: 130,
          key: "orgName"
        },
        {
          title: "所属栏目",
          align: "center",
          width: 100,
          key: "columnName"
        },
        {
          title: "标签分类",
          align: "center",
          minWidth: 100,
          key: "labelName",
          render: (h, params) => {
            // console.log(
            //   this.tableData[params.index].platformLabelVos,
            //   "ljj label分类"
            // );
            let newLabelName = [];
            this.tableData[params.index].platformLabelVos.map(item => {
              newLabelName.push(item.labelName);
            });
            // console.log(newLabelName, "ljj newLabelName");
            let labelNameType = newLabelName.filter(item => item !== 0);
            // console.log(labelNameType, "ljj 过滤");
            return h("div", [
              h(
                  "span",
                  {
                    style: {
                      marginRight: "3px"
                    }
                  },
                  labelNameType.join()
              )
            ]);
          }
        },
        {
          title: "公布时间",
          align: "center",
          width: 240,
          key: "publishTime"
        },
        {
          title: "初始公布方式",
          align: "center",
          minWidth: 100,
          key: "source"
        },
        {
          title: "当前状态",
          align: "center",
          minWidth: 100,
          key: "status"
        },
        {
          title: "置顶",
          align: "center",
          width: 100,
          key: "top",
          render: (h, params) => {
            this.paramsList = params;
            return h("div", [
              h(
                "Checkbox",
                {
                  props: {
                    value: params.row.top == "1" ? true : false
                  },
                  on: {
                    "on-change": value => {
                      value = value === true ? "1" : value === false ? "0" : "";

                      // console.log(value, "change value");
                      this.topFn(params.row, value);
                    }
                  }
                },
                "置顶"
              )
            ]);
          }
        },
        {
          title: "操作",
          align: "center",
          width: 200,
          fixed: "right",
          render: (h, params) => {
            let id = params.row
            return h("div", [
              h(
                "Button",
                {
                  props: {
                    type: "primary",
                    size: "small"
                  },
                  style: {
                    marginRight: "10px"
                  },
                  on: {
                    click: () => {
                      // this.$Message.loading({
                      //   content: "正在加载数据，请稍等...",
                      //   duration: 0,
                      // });
                      this.flagType(id.id);
                      //fuck
                    }
                  }
                },
                "管理标签"
              ),
              h(
                "Button",
                {
                  props: {
                    type:
                      params.row.status === "下架"
                        ? "success"
                        : params.row.status === "公布中"
                        ? "warning"
                        : "",
                    size: "small"
                  },
                  on: {
                    click: () => {
                      this.$Modal.confirm({
                        title: "温馨提示",
                        content:
                          "<p>确定执行" +
                          `${
                              params.row.status === "公布中" ? "<span style='color:red;margin: 0 3px;'>下架</span>" : "<span style='color:#ff0000;margin: 0 3px;'>公布</span>"
                          }` +
                          "该操作吗？</p>",
                        onOk: () => {
                          this.onOkFn(params);
                        }
                      });
                    }
                  }
                },
                params.row.status === "公布中"
                  ? "下架"
                  : params.row.status === "下架"
                  ? "公布"
                  : ""
              )
            ]);
          }
        }
      ],
      //   新增
      addModalStatus: false,
      cancelModelTip: true,
      // 查看
      infoId: "",
      seeModalStatus: false,
      // 导出
      exportArr: []
    };
  },
  methods: {
    size1() {
      this.hadlePageSize({
        page: 1,
        pageSize: this.pageSize
      });
    },
    reset() {
      this.searchForm = {
        status: this.searchForm.status
      }
      this.hadlePageSize({
        page: 1,
        pageSize: this.pageSize
      });
    },
    //管理标签
    flagType(id) {
      this.showMe2 = true
      this.$refs.toggleLabel.save(id)
    },
    flagType1(status) {
      this.showMe2 = status
    },
    //批量下架
    offShelf() {
      if (this.tableSelectArr.length !== 0) {
        this.tableSelectArr.map((item, index) => {
          this.$Modal.confirm({
            title: "温馨提示",
            content:
                "<p>确定执行" + "<span style='color:red;margin: 0 3px;'>下架</span>" + "该操作吗？</p>",
            onOk: () => {
              if (item.status === "公布中") {
                this.onAddFn(item.id, item.status);
              } else {
                this.$Message.warning({
                  background: true,
                  content: "该选项已下架"
                });
                return;
              }
            }
          });
        });
      } else {
        this.$Message.warning({
          background: true,
          content: "请勾选需下架的选项!",
          duration: 1
        });
      }

    },
    //批量公布
    offAnnounce() {
      if (this.tableSelectArr.length !== 0) {
        this.tableSelectArr.map((item, index) => {
          this.$Modal.confirm({
            title: "温馨提示",
            content:
                "<p>确定执行" + "<span style='color:red;margin: 0 3px;'>公布</span>" + "该操作吗？</p>",
            onOk: () => {
              if (item.status === "下架") {
                this.onAddFn(item.id, item.status);
              } else {
                this.$Message.warning({
                  background: true,
                  content: "该选项已公布中"
                });
                return;
              }
            }
          });

        });
      } else {
        this.$Message.warning({
          background: true,
          content: "请勾选需公布的选项!"
        });
      }
    },
    cancel() {
      this.value3 = false;
      this.searchForm = {
        status: this.searchForm.status
      }
    },
    close() {
      if (this.showMe && this.cancelModalTip) {
        this.$Modal.confirm({
          title: "温馨提示",
          content: "您正在关闭此窗口，信息将不会保存，是否继续",
          onOk: () => {
            this.showMe = false;
          }
        });
      } else {
        this.showMe = false;
      }
    },
    down() {
      this.showMe1 = false;
    },
    //添加公布内容弹窗
    toggle() {
      this.$Message.loading({
        content: "正在加载数据，请稍等...",
        duration: 0,
      });
      this.$refs.modelValue.toggleShuju()
      this.showMe = true;
    },
    toggle1() {
      this.$Message.loading({
        content: "正在加载数据，请稍等...",
        duration: 0,
      });
      this.$refs.modelValue1.hadlePageSize({
        page: this.page,
        pageSize: this.pageSize,
      });
      this.showMe1 = true;
    },
    // 表格选中
    tableSelect(data) {
      this.tableSelectArr = JSON.parse(JSON.stringify(data));
    },
    onAddFn(id, status) {
      this.$post("/old/api/pc/information/polymerization/updateStatusBatch", {
        ids: id,
        status: status === "公布中" ? "-1" : status === "下架" ? "4" : ""
      })
        .then(res => {
          if (res.code == 200) {
            this.hadlePageSize({
              page: this.page,
              pageSize: this.pageSize
            });
            this.$Message.success({
              background: true,
              content: status === "公布中" ? "下架成功" : "公布成功"
            });
          } else {
            this.$Message.error({
              background: true,
              content: res.desc
            });
          }
        })
        .catch(err => {
          this.$Message.error({
            background: true,
            content: "更改状态失败，请重试！"
          });
        });
    },
    onOkFn(params) {
      this.$post("/old/api/pc/information/polymerization/updateStatusBatch", {
        ids: params.row.id,
        status:
            params.row.status === "公布中"
                ? "-1"
                : params.row.status === "下架"
                ? "4"
                : ""
      })
        .then(res => {
          if (res.code == 200) {
            this.hadlePageSize({
              page: this.page,
              pageSize: this.pageSize
            });
          } else {
            this.$Message.error({
              background: true,
              content: res.desc
            });
          }
        })
        .catch(err => {
          this.$Message.error({
            background: true,
            content: "更改状态失败，请重试！"
          });
        });
    },
    // 导出
    exportTable() {
      if (this.exportArr.length == 0) {
        this.$Message.warning({
          background: true,
          content: "请先选择需导出的申请记录信息"
        });
        return;
      }
      this.$Modal.confirm({
        title: "温馨提示",
        content: "<p>您正在导出选中的申请信息，是否继续</p>",
        onOk: () => {
          let applyIdArr = [];
          this.exportArr.map(item => {
            applyIdArr.push({
              applyId: item.applyId,
              status: "3"
            });
          });
          this.$post(
            "/pension/api/pc/elderly/applyRecord/updateStatusInList",
            {
              applyRecordUpdateRoList: applyIdArr
            },
            { "Content-Type": "application/json" }
          ).then(res => {
            if (res.code == 200) {
              this.loading = true;
              this.$get(
                "/pension/api/pc/elderly/applyRecord/queryApplyRecordByPage",
                {
                  ...this.searchForm,
                  status:
                    this.searchForm.status == "0" ? "" : this.searchForm.status,
                  page: this.page,
                  pageSize: this.pageSize,
                  custGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
                  orgCode: parent.vue.loginInfo.userinfo.orgCode
                }
              )
                .then(res => {
                  this.loading = false;
                  if (res.code == 200 && res.dataList) {
                    res.dataList.map(item => {
                      item.gmtModified = item.gmtModified
                        ? this.$core.formatDate(
                            new Date(item.gmtModified),
                            "yyyy-MM-dd hh:mm:ss"
                          )
                        : "";
                      item.gmtCreate = this.$core.formatDate(
                        new Date(item.gmtCreate),
                        "yyyy-MM-dd hh:mm:ss"
                      );
                      item.age = item.birthday
                        ? Math.ceil(
                            (new Date().getTime() -
                              new Date(item.birthday).getTime()) /
                              31536000000
                          )
                        : "";
                      item.sex =
                        item.sex == "2"
                          ? "女"
                          : item.sex == "1"
                          ? "男"
                          : item.sex == "0"
                          ? "未知"
                          : "";
                      item.status =
                        item.status == "1"
                          ? "待处理资料未完善"
                          : item.status == "2"
                          ? "待处理资料已完善"
                          : item.status == "3"
                          ? "处理中"
                          : item.status == "4"
                          ? "有结果"
                          : item.status == "5"
                          ? "已过期"
                          : "";
                    });
                    this.tableData = res.dataList;
                    this.page = res.currentPage;
                    this.pageSize = res.pageSize;
                    this.total = res.maxCount;

                    let tHeader = [
                      "姓名",
                      "性别",
                      "手机号码",
                      "出生日期",
                      "年龄",
                      "所属社区",
                      "申请时间",
                      "处理时间",
                      "状态"
                    ];
                    let filterVal = [
                      "name",
                      "sex",
                      "phone",
                      "birthday",
                      "age",
                      "orgName",
                      "gmtCreate",
                      "gmtModified",
                      "status"
                    ];
                    let exportArr = [];
                    this.exportArr.map(item => {
                      this.tableData.map(subItem => {
                        if (item.applyId == subItem.applyId) {
                          exportArr.push(subItem);
                        }
                      });
                    });
                    this.$core.exportExcel(
                      tHeader,
                      filterVal,
                      exportArr,
                      "申请记录"
                    );
                    this.exportArr = [];
                  } else {
                    this.$Message.error({
                      background: true,
                      content: res.desc
                    });
                  }
                })
                .catch(err => {
                  console.log(err);
                  this.loading = false;
                  this.$Message.error({
                    background: true,
                    content: "获取数据失败，请重试"
                  });
                });
            } else {
              this.$Message.error({
                background: true,
                content: res.desc
              });
            }
          });
        }
      });
    },
    //   新增
    saveAdd() {
      this.$refs.addApply.openPromiseModal();
    },
    saveSuccess() {
      this.cancelModelTip = false;
      this.addModalStatus = false;
      this.hadlePageSize({
        page: this.page,
        pageSize: this.pageSize
      });
    },
    addModalStatusFn(status) {
      if (!status && this.cancelModelTip) {
        this.$Modal.confirm({
          title: "温馨提示",
          content: "<p>确认要关闭窗口吗？未保存的信息不会保存</p>",
          onOk: () => {
            this.addModalStatus = status;
          }
        });
      } else {
        this.addModalStatus = status;
      }
    },
    search() {
      this.hadlePageSize({
        page: 1,
        pageSize: this.pageSize
      });
    },
    //分页数据请求
    hadlePageSize(obj) {
      this.loading = true;
      this.$get("/old/api/pc/information/polymerization/queryPage", {
        ...this.searchForm,
        status: this.searchForm.status == "0" ? "" : this.searchForm.status,
        page: obj.page,
        pageSize: obj.pageSize,
        custGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
        userOrgCode: parent.vue.loginInfo.userinfo.orgCode,
        oemCode: parent.vue.oemInfo.oemCode
      })
        .then(res => {
          this.loading = false;
          if (res.code == 200 && res.dataList) {
            res.dataList.map(item => {
              item.publishTime = item.publishTime
                ? this.$core.formatDate(
                    new Date(item.publishTime),
                    "yyyy-MM-dd hh:mm:ss"
                  )
                : "";
              item.gmtCreate = this.$core.formatDate(
                  new Date(item.gmtCreate),
                  "yyyy-MM-dd hh:mm:ss"
              );
              item.age = item.birthday
                  ? Math.ceil(
                      (new Date().getTime() - new Date(item.birthday).getTime()) /
                      31536000000
                  )
                  : "";
              item.status =
                  item.status == "4"
                      ? "公布中"
                      : item.status == "-1"
                      ? "下架"
                      : item.status == "0"
                          ? "未知"
                          : "";
            });
            this.tableData = res.dataList;
           
            this.page = res.currentPage;
            this.pageSize = res.pageSize;
            this.total = res.maxCount;
          } else {
            this.$Message.error({
              background: true,
              content: res.desc
            });
          }
        })
        .catch(err => {
          console.log(err);
          this.loading = false;
          this.$Message.error({
            background: true,
            content: "获取数据失败，请重试"
          });
        });
    },
    //置顶
    topFn(obj, value) {
      this.$post("/old/api/pc/information/polymerization/updateTop", {
        id: obj.id,
        top: value
      })
        .then(res => {
          if (res.code === "200") {
          } else {
            this.$Message.error({
              background: true,
              content: res.desc
            });
          }
        })
        .catch(err => {
          console.log(err);
          this.$Message.error({
            background: true,
            content: "获取数据失败，请重试"
          });
        });
    }
  },
  created() {
    this.hadlePageSize({
      page: this.page,
      pageSize: this.pageSize
    });
  },
  components: {
    LiefengMain,
    LiefengTable,
    announce,
    labelSettings,
    labelToggle
  }
};
</script>

<style scoped lang='less'>
/deep/ #toolsbarRight {
  width: 1000%;

  .ivu-form-item {
    margin-bottom: 0;
  }
}

.addModal {
  /deep/ .ivu-icon-ios-close {
    display: none;
  }
}

.demo-drawer-footer {
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  border-top: 1px solid #e8e8e8;
  padding: 10px 16px;
  text-align: right;
  background: #fff;
}
</style>
